import PropTypes from "prop-types";
import Select from "react-select";

const getCustomStyles = () => ({
  control: provided => ({
    ...provided,
    position: "relative",
    fontSize: "14px",
    borderColor: "black",
    cursor: "pointer",
  }),
  dropdownIndicator: provided => ({
    ...provided,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  menu: provided => ({
    ...provided,
  }),
  option: provided => ({
    ...provided,
    fontSize: "14px",
  }),
  multiValue: provided => ({
    ...provided,
  }),
});

export const Dropdown = ({
  options,
  title,
  onChange,
  isMulti = true,
  defaultValue = null,
  isDisabled = false,
}) => {
  const customStyles = getCustomStyles();

  return (
    <Select
      options={options}
      isSearchable={false}
      isMulti={isMulti}
      styles={customStyles}
      closeMenuOnSelect={!isMulti && true}
      onChange={onChange}
      placeholder={title.toUpperCase()}
      name={title}
      defaultValue={defaultValue}
      noOptionsMessage={() => null}
      isDisabled={isDisabled}
    />
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }),
  isDisabled: PropTypes.bool,
};
