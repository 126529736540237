import { Container } from "react-bootstrap";

import { useAppUser } from "@dpdgroupuk/mydpd-app";
import { APP_ROLES } from "@dpdgroupuk/mydpd-enums";

import { ACTION_DATES_LIMIT_TYPE } from "../constants/dashboards";
import DeliveryServiceSummary from "../components/DeliveryServiceSummary/DeliveryServiceSummary";
import DeliveryServiceUndelivered from "../components/DeliveryServiceUndelivered/DeliveryServiceUndelivered";
import { NoAccess } from "../components/NoAccess/NoAccess";
import { useSelector } from "react-redux";
import { UmsSelectors } from "../redux";

const Reports = () => {
  const { priorityAccount } = useSelector(UmsSelectors.getCustomer);
  const { actionDatesLimitType } = useSelector(UmsSelectors.getCustomerPrefs);
  const { appRoles } = useAppUser();

  const hasReportAccess =
    appRoles.includes(APP_ROLES.REPORTS) && priorityAccount;

  const perishableAccess =
    actionDatesLimitType === ACTION_DATES_LIMIT_TYPE.PMA ||
    actionDatesLimitType === ACTION_DATES_LIMIT_TYPE.PER ||
    actionDatesLimitType === ACTION_DATES_LIMIT_TYPE.PRT;

  return (
    <>
      {hasReportAccess ? (
        <Container>
          {perishableAccess ? (
            <DeliveryServiceUndelivered />
          ) : (
            <DeliveryServiceSummary />
          )}
        </Container>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default Reports;
