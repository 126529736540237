export const REPORT_TYPES = {
  VERY_IMPORTANT_ACCOUNT: "via",
  PERISHABLE: "per",
};

export const ACTION_DATES_LIMIT_TYPE = {
  PMA: "PMA",
  PER: "PER",
  PRT: "PRT",
};
