import ActionTypes from "./actionTypes";

export const initialState = {
  reportData: {},
  filterOptions: {},
  isPDFExported: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_REPORTS_DATA.SUCCESS: {
      return {
        ...state,
        reportData: action.payload,
      };
    }
    case ActionTypes.GET_REPORTS_DATA.FAILURE: {
      return {
        ...state,
        reportData: initialState.reportData,
      };
    }
    case ActionTypes.INITIALIZE_FILTERS: {
      return {
        ...state,
        filterOptions: action.payload,
      };
    }
    case ActionTypes.SET_IS_PDF_EXPORTED: {
      return {
        ...state,
        isPDFExported: action.payload,
      };
    }
    default:
      return state;
  }
};
