import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import {
  Bar,
  Cell,
  LabelList,
  BarChart as RechartBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

import { Card } from "@dpdgroupuk/mydpd-ui";

import { NoResults } from "../NoResults/NoResults";

const DEFAULT_RED = "#F77B72";
const LIGHT_RED = "#FFB5A4";

export const BarChart = ({
  data,
  colSpan = 4,
  title,
  labelFontSize = "10px",
}) => {
  return (
    <Col md={colSpan}>
      <Card>
        <Card.Title>{title}</Card.Title>
        {data.length === 0 ? (
          <NoResults />
        ) : (
          <ResponsiveContainer width="100%" height={200}>
            <RechartBarChart
              data={data}
              margin={{ top: 15, right: 5, left: 5, bottom: 5 }}
            >
              <XAxis dataKey="name" fontSize={"13px"} />
              <Tooltip cursor={false} formatter={value => `${value}%`} />
              <Bar dataKey="count">
                <LabelList
                  dataKey={"count"}
                  position={"top"}
                  formatter={value => `${value}%`}
                  fontSize={labelFontSize}
                />
                {data.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      data.length > 4
                        ? index % 2 === 0
                          ? DEFAULT_RED
                          : LIGHT_RED
                        : DEFAULT_RED
                    }
                  />
                ))}
              </Bar>
            </RechartBarChart>
          </ResponsiveContainer>
        )}
      </Card>
    </Col>
  );
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.string.isRequired,
    })
  ).isRequired,
  colSpan: PropTypes.number,
  title: PropTypes.string.isRequired,
  labelFontSize: PropTypes.string,
};
