const HEADERS = {
  TOTAL_PARCEL_VOLUME: "Total Parcel Volume",
  DPD_EXCEPTIONS: "DPD Exceptions",
  DPD_SERVCE: "DPD Service",
  SERVICE: "Service",
  PARCELS: "Parcels",
  PERCENTAGE_OF_PARCELS: "% of Parcels",
  EXCEPTION: "Exception",
  DELIVERY_DEPOT: "Delivery Depot",
  VOLUME: "Volume",
  UNDELIVERED: "Undelivered",
  PERCENTAGE_OF_UNDELIVERED_PARCELS: "Undelivered %",
  RECONSIGNED: "Reconsigned",
  PERCENTAGE_OF_RECONSIGNED_PARCELS: "Reconsigned %",
  PERCENTAGE_OF_EXCEPTIONS: "% of Exceptions",
};

const ACCESSORS = {
  TOTAL_PARCEL_VOLUME: "totalParcelVolume",
  PERCENTAGE_OF_PARCELS: "percentageOfParcels",
  TOTAL_CUSTOMER_SERVICE_PERCENTAGE: "totalCustomerServicePercent",
  TOTAL_EXCEPTIONS: "totalExceptions",
  SERVICE: "service",
  EXCEPTION: "exception",
  DEPOT: "depot",
  TOTAL_UNDELIVERED_PARCELS: "totalUndeliveredParcels",
  PERCENTAGE_OF_UNDELIVERED_PARCELS: "percentageOfUndeliveredParcels",
  RECONSIGNED: "totalReconsigned",
  PERCENTAGE_OF_RECONSIGNED_PARCELS: "percentageOfReconsignedParcels",
};

export const dpdServiceBreakdownColumns = [
  { Header: HEADERS.SERVICE, accessor: ACCESSORS.SERVICE },
  { Header: HEADERS.PARCELS, accessor: ACCESSORS.TOTAL_PARCEL_VOLUME },
  {
    Header: HEADERS.DPD_SERVCE,
    accessor: ACCESSORS.TOTAL_CUSTOMER_SERVICE_PERCENTAGE,
  },
  {
    Header: HEADERS.PERCENTAGE_OF_PARCELS,
    accessor: ACCESSORS.PERCENTAGE_OF_PARCELS,
  },
];

export const dpdFailureReasonColumns = [
  { Header: HEADERS.EXCEPTION, accessor: ACCESSORS.EXCEPTION },
  { Header: HEADERS.PARCELS, accessor: ACCESSORS.TOTAL_PARCEL_VOLUME },
  {
    Header: HEADERS.PERCENTAGE_OF_PARCELS,
    accessor: ACCESSORS.PERCENTAGE_OF_PARCELS,
  },
];

export const dpdDepotBreakdownColumns = [
  { Header: HEADERS.DELIVERY_DEPOT, accessor: ACCESSORS.DEPOT },
  { Header: HEADERS.PARCELS, accessor: ACCESSORS.TOTAL_PARCEL_VOLUME },
  {
    Header: HEADERS.DPD_SERVCE,
    accessor: ACCESSORS.TOTAL_CUSTOMER_SERVICE_PERCENTAGE,
  },
  {
    Header: HEADERS.PERCENTAGE_OF_PARCELS,
    accessor: ACCESSORS.PERCENTAGE_OF_PARCELS,
  },
];

export const dpdCustomerFailureReasonColumns = [
  { Header: HEADERS.EXCEPTION, accessor: ACCESSORS.EXCEPTION },
  { Header: HEADERS.PARCELS, accessor: ACCESSORS.TOTAL_PARCEL_VOLUME },
  {
    Header: HEADERS.PERCENTAGE_OF_PARCELS,
    accessor: ACCESSORS.PERCENTAGE_OF_PARCELS,
  },
];

export const dpdRecipientFailureReasonColumns = [
  { Header: HEADERS.EXCEPTION, accessor: ACCESSORS.EXCEPTION },
  { Header: HEADERS.PARCELS, accessor: ACCESSORS.TOTAL_PARCEL_VOLUME },
  {
    Header: HEADERS.PERCENTAGE_OF_PARCELS,
    accessor: ACCESSORS.PERCENTAGE_OF_PARCELS,
  },
];

export const dpdUndeliveredResults = [
  { Header: "delivery", accessor: "delivery" },
  { Header: HEADERS.PARCELS, accessor: ACCESSORS.TOTAL_PARCEL_VOLUME },
];

export const dpdUndeliveredDepotBreakdownColumns = [
  { Header: HEADERS.DELIVERY_DEPOT, accessor: ACCESSORS.DEPOT },
  { Header: HEADERS.VOLUME, accessor: ACCESSORS.TOTAL_PARCEL_VOLUME },
  {
    Header: HEADERS.UNDELIVERED,
    accessor: ACCESSORS.TOTAL_UNDELIVERED_PARCELS,
  },
  {
    Header: HEADERS.PERCENTAGE_OF_UNDELIVERED_PARCELS,
    accessor: ACCESSORS.PERCENTAGE_OF_UNDELIVERED_PARCELS,
  },
  { Header: HEADERS.RECONSIGNED, accessor: ACCESSORS.RECONSIGNED },
  {
    Header: HEADERS.PERCENTAGE_OF_RECONSIGNED_PARCELS,
    accessor: ACCESSORS.PERCENTAGE_OF_RECONSIGNED_PARCELS,
  },
];

export const FILTERS = {
  ACCOUNT: "Account",
  DIRECTION: "Direction",
  WEEK: "Week",
  DAY: "Day",
  SERVICE: "Shipped Service",
  DPD_EXCEPTION: "DPD Exception",
  DEPOT: "Delivery Depot",
  DELIVERY_WEEK: "Delivery Week",
  UNDELIVERED_REASON: "Undelivered Reason",
  CUSTOMER_EXCEPTION: "Customer Exception",
  RECIPIENT_EXCEPTION: "Recipient Exception",
};
