import { compose, withProps } from "recompose";
import { connect } from "react-redux";

import { withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import { withLoader, withOverlay } from "@dpdgroupuk/mydpd-ui";

import { ReportsActions } from "../redux";
import {
  formatFilterOptions,
  formatUndeliveredFilterOptions,
} from "../helpers/filter";
import { REPORT_TYPES } from "../constants/dashboards";

const withReport = ({ report }) =>
  compose(
    withAppUserPreferences,
    withOverlay,
    connect(
      state => {
        const accounts = state.app.auth.user.accounts;
        return { accounts };
      },
      dispatch => ({
        fetchReportsData: (reportType, params) =>
          dispatch(ReportsActions.fetchReportsData(reportType, params)),
        initializeFilters: params =>
          dispatch(ReportsActions.initializeFilters(params)),
      })
    ),
    withLoader({
      loadFn: async ({ accounts, fetchReportsData, initializeFilters }) => {
        const req = { dpdAccounts: [...accounts] };
        const data = await fetchReportsData(report, req);
        const filters = { ...data, accounts };

        let formattedFilters;
        if (report === REPORT_TYPES.VERY_IMPORTANT_ACCOUNT) {
          formattedFilters = formatFilterOptions(filters);
        } else if (report === REPORT_TYPES.PERISHABLE) {
          formattedFilters = formatUndeliveredFilterOptions(filters);
        }

        initializeFilters(formattedFilters);
      },
    }),
    withProps(report)
  );

export default withReport;
