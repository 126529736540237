import { FILTERS } from "../constants/tables";

const sortAlphabetically = array => {
  return array.sort((a, b) => a.label.localeCompare(b.label));
};

const reverseArr = array => {
  return array.reverse();
};

const mapFilters = (items, key = null) => {
  const filters = items.map(item => {
    const itemValue = key ? item[key] : item;
    return { label: itemValue, value: itemValue };
  });

  return [...filters];
};

export const formatFilterOptions = data => {
  return [
    [
      {
        title: FILTERS.DAY,
        options: reverseArr(mapFilters(data.serviceDailyView, "name")),
      },
      {
        title: FILTERS.WEEK,
        options: reverseArr(mapFilters(data.serviceWeeklyView, "name")),
      },
    ],
    [
      {
        title: FILTERS.ACCOUNT,
        options: sortAlphabetically(mapFilters(data.accounts)),
      },
      {
        title: FILTERS.DIRECTION,
        options: sortAlphabetically(mapFilters(["Inbound", "Outbound"])),
      },
      {
        title: FILTERS.SERVICE,
        options: sortAlphabetically(
          mapFilters(data.serviceBreakdown, "service")
        ),
      },
      {
        title: FILTERS.DEPOT,
        options: sortAlphabetically(mapFilters(data.depotBreakdown, "depot")),
      },
    ],
    [
      {
        title: FILTERS.DPD_EXCEPTION,
        options: sortAlphabetically(
          mapFilters(data.failureReason, "exception")
        ),
      },
      {
        title: FILTERS.CUSTOMER_EXCEPTION,
        options: sortAlphabetically(
          mapFilters(data.customerFailureReason, "exception")
        ),
      },
      {
        title: FILTERS.RECIPIENT_EXCEPTION,
        options: sortAlphabetically(
          mapFilters(data.recipientFailureReason, "exception")
        ),
      },
    ],
  ];
};

export const formatUndeliveredFilterOptions = data => {
  return [
    [
      {
        title: FILTERS.ACCOUNT,
        options: sortAlphabetically(mapFilters(data.accounts)),
      },
      {
        title: FILTERS.UNDELIVERED_REASON,
        options: sortAlphabetically(
          mapFilters(data.undeliveredResults, "delivery")
        ),
      },
      {
        title: FILTERS.DAY,
        options: reverseArr(mapFilters(data.undeliveredDailyView, "name")),
      },
    ],
  ];
};
