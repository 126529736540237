export const deepArrayClone = array => {
  return array.map(item => {
    if (Array.isArray(item)) {
      return deepArrayClone(item);
    } else if (typeof item === "object" && item !== null) {
      return deepObjectClone(item);
    }
    return item;
  });
};

export const deepObjectClone = obj => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepObjectClone);
  }

  const clonedObj = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = deepObjectClone(obj[key]);
    }
  }

  return clonedObj;
};
