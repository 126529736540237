import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("REPORTS", {
  GET_REPORTS_DATA: createAsyncActionTypes("GET_REPORTS_DATA"),
  INITIALIZE_FILTERS: "INITIALIZE_FILTERS",
  SET_IS_PDF_EXPORTED: "SET_IS_PDF_EXPORTED",
});
