import { useMemo } from "react";

import { Header, useAppUser } from "@dpdgroupuk/mydpd-app";
import { APP_ROLES } from "@dpdgroupuk/mydpd-enums";

import { ROUTES } from "../../router/constants";

export const AppHeader = ({ ...headerProps }) => {
  const { appRoles } = useAppUser();

  const secondaryMenuItems = useMemo(() => {
    const links = [
      {
        href: ROUTES.WELCOME,
        name: "Welcome",
      },
      {
        href: ROUTES.ACCOUNT,
        name: "Account Details",
      },
    ];

    if (appRoles.includes(APP_ROLES.REPORTS)) {
      links.push({
        href: ROUTES.REPORTS,
        name: "Reports",
      });
    }

    links.push({
      href: ROUTES.API,
      name: "API",
    });

    return links;
  }, [appRoles]);

  return <Header secondaryMenuItems={secondaryMenuItems} {...headerProps} />;
};
