import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";

import { combineAppReducer } from "@dpdgroupuk/mydpd-app";

import { ReportsReducer } from "./reports";
import { UmsReducer } from "./ums";

export default combineReducers({
  form: reduxFormReducer,
  app: combineAppReducer({
    ums: UmsReducer,
  }),
  reports: ReportsReducer,
});
