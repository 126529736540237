import { Col, Container, Row } from "react-bootstrap";

export const NoAccess = () => {
  return (
    <Col md={12} className="text-center h-100 d-flex align-items-center">
      <Container>
        <Row>
          <Col md={12}>
            <img
              className="error-img"
              src="/static/media/little-problem.b828c1fa3072d68e2ea48f2862d8229f.svg"
              alt="Access Denied"
            />
          </Col>
          <Col md={12}>
            <span className="error-message">Access Denied</span>
          </Col>
          <Col md={12} className="mt-3">
            <span className="error-subtitle">
              You don’t have sufficient permissions to use reports
            </span>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};
