import { createSelector } from "reselect";

export const getReports = state => state.reports;

export const getReportData = createSelector(
  getReports,
  reports => reports.reportData
);

export const getFilterOptions = createSelector(
  getReports,
  reports => reports.filterOptions
);

export const getIsPDFExported = createSelector(
  getReports,
  reports => reports.isPDFExported
);
