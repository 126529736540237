import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import JSZip from "jszip";
import Papa from "papaparse";
import PropTypes from "prop-types";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { compose } from "recompose";

import { useOverlay, withOverlay } from "@dpdgroupuk/mydpd-ui";
import { withAppUserPreferences } from "@dpdgroupuk/mydpd-app";

import { ReportsActions, ReportsSelectors } from "../../redux";
import { createReportFileName } from "../../utils/str";

export const DownloadBtn = ({ setIsPDFExported }) => {
  const reportData = useSelector(ReportsSelectors.getReportData);
  const overlay = useOverlay();

  const handlePDFDownload = async () => {
    setIsPDFExported(true);
    overlay.show();

    // Delay for 500 milliseconds to disable styles
    await new Promise(resolve => setTimeout(resolve, 500));

    const element = document.querySelector("#pdf");
    const dataUrl = await htmlToImage.toPng(element);

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      unit: "pt",
      format: [element.offsetWidth, element.offsetHeight],
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (element.offsetHeight * pdfWidth) / element.offsetWidth;

    const filename = createReportFileName("pdf");
    pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(filename);

    setIsPDFExported(false);
    overlay.hide();
  };

  const handleCSVDownload = async () => {
    const data = { ...reportData, stats: [reportData.stats] };
    const zip = new JSZip();

    // Add each CSV file to the zip folder
    for (const key of Object.keys(data)) {
      const csvDataArray = Object.values(data[key]);
      const csvContent = Papa.unparse(csvDataArray, { header: true });
      zip.file(`${key}.csv`, csvContent);
    }

    // Generate the zip file
    const content = await zip.generateAsync({ type: "blob" });

    // Create a download link for the zip file
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(content);
    downloadLink.download = createReportFileName("zip");
    downloadLink.click();

    // Clean up the temporary URL object
    URL.revokeObjectURL(downloadLink.href);
  };

  return (
    <DropdownButton as={ButtonGroup} title="Download" className="ml-3">
      <Dropdown.Item eventKey="1" onClick={handleCSVDownload}>
        CSV
      </Dropdown.Item>
      <Dropdown.Item eventKey="2" onClick={handlePDFDownload}>
        PDF
      </Dropdown.Item>
    </DropdownButton>
  );
};

DownloadBtn.propTypes = {
  setIsPDFExported: PropTypes.func,
};

export default compose(
  withAppUserPreferences,
  withOverlay,
  connect(null, dispatch => ({
    setIsPDFExported: params =>
      dispatch(ReportsActions.setIsPDFExported(params)),
  }))
)(DownloadBtn);
