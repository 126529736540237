import {
  createAsyncAction,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import { reports } from "../../apis";
import ActionTypes from "./actionTypes";

export const fetchReportsData = createAsyncAction(
  (reportType, query) =>
    reports.fetchReportsData(reportType, query).then(({ data }) => data),
  ActionTypes.GET_REPORTS_DATA
);

export const initializeFilters = filters =>
  createPayloadAction(ActionTypes.INITIALIZE_FILTERS, filters);

export const setIsPDFExported = isPDFExported =>
  createPayloadAction(ActionTypes.SET_IS_PDF_EXPORTED, isPDFExported);
