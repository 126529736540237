import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { Card } from "@dpdgroupuk/mydpd-ui";

import styles from "./OverviewStats.module.scss";

export const OverviewStats = ({ stats, colSpan = 4 }) => {
  return (
    <Col md={colSpan}>
      <Card className={styles.overviewStats}>
        {stats.map(({ title, value }) => (
          <div key={title} className="py-1">
            <h4 className={styles["overviewStats-title"]}>{title}</h4>
            <h2 className={styles["overviewStats-value"]}>{value}</h2>
          </div>
        ))}
      </Card>
    </Col>
  );
};

OverviewStats.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  colSpan: PropTypes.number,
};
