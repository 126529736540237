import { Redirect, Route } from "react-router";
import { Switch } from "react-router-dom";

import Reports from "../pages/Reports";
import { ROUTES } from "./constants";

export const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={ROUTES.REPORTS} />
      <Route exact strict path={ROUTES.REPORTS} component={Reports} />
    </Switch>
  );
};
