import PropTypes from "prop-types";
import { useSortBy, useTable } from "react-table";

import styles from "./Table.module.scss";
import { NoResults } from "../NoResults/NoResults";
import { Col } from "react-bootstrap";
import { Card } from "@dpdgroupuk/mydpd-ui";

export const Table = ({
  colSpan = 4,
  title,
  data,
  columns,
  className = "",
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <Col md={colSpan}>
      <Card className={className}>
        <Card.Title>{title}</Card.Title>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr
                key={headerGroup.id}
                className={styles.header}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr key={row.id} className={styles.body} {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td key={cell.row.original.id} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {data.length === 0 && <NoResults />}
      </Card>
    </Col>
  );
};

Table.propTypes = {
  colSpan: PropTypes.number,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFilterBtnClick: PropTypes.func,
  className: PropTypes.string,
};
