import { useMemo } from "react";

import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import FilterOptions from "../FilterOptions/FilterOptions";
import { OverviewStats } from "../OverviewStats/OverviewStats";
import { ReportsSelectors } from "../../redux";
import { Table } from "../Table/Table";
import { REPORT_TYPES } from "../../constants/dashboards";
import {
  dpdCustomerFailureReasonColumns,
  dpdDepotBreakdownColumns,
  dpdFailureReasonColumns,
  dpdRecipientFailureReasonColumns,
  dpdServiceBreakdownColumns,
} from "../../constants/tables";
import styles from "./DeliveryServiceSummary.module.scss";
import { BarChart } from "../BarChart/BarChart";
import withReport from "../../HOCs/withReport";

const DeliveryServiceSummary = () => {
  const isPDFExported = useSelector(ReportsSelectors.getIsPDFExported);
  const {
    stats,
    serviceWeeklyView,
    serviceDailyView,
    serviceBreakdown,
    failureReason,
    depotBreakdown,
    customerFailureReason,
    recipientFailureReason,
  } = useSelector(ReportsSelectors.getReportData);

  const overviewStats = useMemo(
    () => [
      {
        title: "DPD Service",
        value: stats.totalCustomerServicePercent,
      },
      {
        title: "Total Parcel Volume",
        value: stats.totalParcelVolume,
      },
      {
        title: "DPD Exceptions",
        value: stats.totalExceptions,
      },
    ],
    [stats]
  );

  return (
    <>
      <FilterOptions report={REPORT_TYPES.VERY_IMPORTANT_ACCOUNT} />
      <div id="pdf">
        <Row className="mt-3">
          <OverviewStats stats={overviewStats} />
          <BarChart
            data={serviceWeeklyView}
            orientation="horizontal"
            colSpan={8}
            title="DPD Service - Weekly View"
          />
        </Row>
        <Row className="mt-3">
          <BarChart
            data={serviceDailyView}
            title="DPD Service - Daily View"
            labelFontSize="8px"
            orientation="vertical"
            showYAxis={false}
            colSpan={12}
          />
        </Row>
        <Row className="mt-3">
          <Table
            colSpan={6}
            data={serviceBreakdown}
            columns={dpdServiceBreakdownColumns}
            title="Shipped Service"
          />
          <Table
            colSpan={6}
            data={failureReason}
            columns={dpdFailureReasonColumns}
            title="DPD Exceptions"
          />
        </Row>
        <Row className="mt-3">
          <Table
            colSpan={12}
            data={depotBreakdown}
            columns={dpdDepotBreakdownColumns}
            title="Delivery Depot"
            className={!isPDFExported && styles.depotBreakdown}
          />
        </Row>
        <Row className="my-3">
          <Table
            colSpan={6}
            data={customerFailureReason}
            columns={dpdCustomerFailureReasonColumns}
            title="Customer Exceptions"
          />
          <Table
            colSpan={6}
            data={recipientFailureReason}
            columns={dpdRecipientFailureReasonColumns}
            title="Recipient Exceptions"
          />
        </Row>
      </div>
    </>
  );
};

export default withReport({ report: REPORT_TYPES.VERY_IMPORTANT_ACCOUNT })(
  DeliveryServiceSummary
);
